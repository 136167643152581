import { useRequest } from '@/composables/useRequest';
import { API_SUCCESS_STATUS_CODE, API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { ModifyGroupRequest } from '@/types/group/group-request.type';
import type { GroupMigrationResponse } from '@/types/group/group-response.type';
import type { RecentlyApprovedGroupResponse } from '@/types/group-home/group-home-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427834838
export const createGroupApi = async (
  groupName: string
): Promise<{ groupId: string } | undefined> => {
  const { data } = await useRequest<{ groupId: string }>(`${apiUrl}/groups`, {
    method: ApiMethod.Post,
    data: camelToSnake({ groupName }),
    showCommonError: false,
    headers: generateHeader('X-Nation', 'X-Lang')
  });
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427843034
export const validationGroupsApi = async (): Promise<{ creatable: boolean } | undefined> => {
  const { data } = await useRequest<{ creatable: boolean }>(`${apiUrl}/groups/validation`, {
    method: ApiMethod.Get,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=427834841
export const deleteGroupApi = async (
  groupId: string
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/group/${groupId}`, {
    method: ApiMethod.Delete,
    showCommonError: false
  });
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=447762356
export const modifyGroupApi = async (
  groupId: string,
  requestData: ModifyGroupRequest
): Promise<{ groupId: string } | undefined> => {
  const { data } = await useRequest<{ groupId: string }>(`${apiUrl}/groups/${groupId}`, {
    method: ApiMethod.Put,
    data: camelToSnake(requestData),
    showCommonError: false,
    headers: generateHeader('X-Nation', 'X-Lang')
  });
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=449995741
export const applyToGroupMembershipByInvitedLinkApi = async (
  invitationLinkId: string
): Promise<string | undefined> => {
  const { data } = await useRequest<{ groupName: string }>(`${apiUrl}/group-members/apply`, {
    method: ApiMethod.Post,
    data: camelToSnake({ invitationLinkId }),
    showCommonError: false
  });
  return data?.groupName;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=452411089
export const fetchLatestSignupApprovalToGroupApi = async (): Promise<
  RecentlyApprovedGroupResponse | undefined
> => {
  const { data } = await useRequest<RecentlyApprovedGroupResponse>(
    `${apiUrl}/group-members/recent-join-approve`,
    {
      method: ApiMethod.Get,
      showCommonError: false
    }
  );
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=456754696
export const fetchGroupMigration = async (
  groupId: string
): Promise<GroupMigrationResponse | undefined> => {
  const { data } = await useRequest<GroupMigrationResponse>(
    `${apiUrl}/migration/project/${groupId}/unavailable`,
    {
      method: ApiMethod.Get,
      showCommonError: false
    }
  );
  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+selection+history+%7C+Update+group+selection+history
export const groupHistoryTrackingApi = async (groupIds: Array<string>): Promise<boolean> => {
  const data = await useRequest<{ statusText: string; status: number }>(
    `${apiUrl}/group/selection/history`,
    {
      method: ApiMethod.Put,
      data: { group_ids: groupIds }
    }
  );
  return data?.status === API_SUCCESS_STATUS_CODE;
};
